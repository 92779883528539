import "./App.css";
import MailchimpSubscribe from "react-mailchimp-subscribe"


const url =
  "https://gmail.us21.list-manage.com/subscribe/post?u=fcaa469e699e3d3ee665c528d&amp;id=dd5fc878ee&amp;f_id=00f0c2e1f0";

// simplest form (only email)
const SimpleForm = () => <MailchimpSubscribe url={url} />;

function App() {
  return (
  //   <div className="home">
  //     <div className="logoDiv">
  //       {" "}
  //       <h1 className="Logo">&</h1>
  //     </div>

  //     <div className="contentDiv">
  //       {" "}
  //       <p className="content">Coming Soon</p>
  //     </div>

  //     <div className="subContentDiv">
  //       {" "}
  //       <p className="subContent">
  //         In the meantime, Sign up for our monthly <br /> newsletter to stay up
  //         to date.
  //       </p>
  //     </div>

  //     <div className="inputFieldDiv">
  //       <input placeholder="Email Address" type="text" className="inputField" />
  //       <button class="btn ">Sign Up</button>
  //     </div>

  //     {/* <MailchimpSubscribe
  //   url={url}
  //   render={({ subscribe, status, message }) => (
  //     <div>
  //       <SimpleForm onSubmitted={formData => subscribe(formData)} />
  //       {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
  //       {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
  //       {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
  //     </div>
  //   )}
  // /> */}

      
  //   </div>
  <div>
{/* <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 40" display="none" width="0" height="0">
        <symbol id="icon-901" viewBox="0 0 40 40">
            <path
                d="M34.9,30.5V15.6c-0.4,0.4-0.8,0.9-1.4,1.2c-3.4,2.7-6.2,4.8-8.2,6.6c-0.6,0.5-1.1,0.9-1.6,1.2c-0.4,0.3-0.9,0.6-1.7,0.9 c-0.7,0.3-1.4,0.5-2,0.5l0,0c-0.6,0-1.2-0.2-2-0.5c-0.7-0.3-1.2-0.6-1.7-0.9c-0.4-0.3-0.9-0.7-1.6-1.2c-2.1-1.7-4.8-3.8-8.2-6.6 c-0.5-0.4-0.9-0.8-1.4-1.2v14.9c0,0.2,0.1,0.3,0.2,0.4C5.7,31,5.9,31.1,6,31.1h28.4c0.2,0,0.3-0.1,0.4-0.2 C34.8,30.8,34.9,30.7,34.9,30.5L34.9,30.5z M34.9,10.2V9.7c0,0,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.1,0-0.1-0.2 c0-0.1-0.1-0.2-0.2-0.1c-0.1,0-0.2,0-0.3,0H5.8C5.6,8.9,5.4,9,5.3,9.1C5.2,9.2,5.1,9.3,5.1,9.5c0,2.2,0.9,4,2.8,5.5 c2.5,2,5.1,4,7.7,6.1c0.1,0.1,0.3,0.2,0.7,0.5c0.4,0.3,0.6,0.5,0.9,0.7c0.2,0.2,0.5,0.4,0.8,0.6c0.3,0.2,0.7,0.4,0.9,0.5 c0.3,0.1,0.6,0.2,0.8,0.2l0,0c0.2,0,0.5-0.1,0.8-0.2c0.3-0.1,0.6-0.3,0.9-0.5c0.3-0.2,0.6-0.4,0.8-0.6c0.2-0.2,0.5-0.4,0.9-0.7 c0.4-0.3,0.6-0.5,0.6-0.5c2.7-2.1,5.3-4.2,7.7-6.1c0.7-0.5,1.4-1.2,2-2.2C34.6,11.8,34.9,11,34.9,10.2L34.9,10.2z M37.3,9.5v21 c0,0.8-0.3,1.6-0.9,2.2s-1.4,0.9-2.2,0.9H5.8c-0.8,0-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.4-0.9-2.2v-21c0-0.8,0.3-1.6,0.9-2.2 s1.4-0.9,2.2-0.9h28.4c0.8,0,1.6,0.3,2.2,0.9S37.3,8.7,37.3,9.5z" />
        </symbol>
        <symbol id="icon-a37" viewBox="0 0 40 40">
            <path
                d="M34.1,19.9c0,0.3-0.1,0.3-0.1,0.4L21.3,33.1c-0.1,0.1-0.1,0.1-0.4,0.1c-0.3,0-0.3-0.1-0.4-0.1L19,31.6 c-0.1-0.1-0.1-0.1-0.1-0.4s0.1-0.3,0.1-0.4l9.2-9.2H5.8c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0c-0.1-0.1-0.1-0.2-0.1-0.4v-2.5 c0-0.2,0.1-0.4,0.1-0.5c0,0,0.1,0,0.2,0h22.4L19,9c-0.1-0.1-0.1-0.1-0.1-0.4c0-0.3,0.1-0.3,0.1-0.4l1.5-1.5c0.1-0.1,0.1-0.1,0.4-0.1 c0.3,0,0.3,0.1,0.4,0.1L34,19.5C34,19.6,34.1,19.6,34.1,19.9z" />
        </symbol>
        <symbol id="icon-906" viewBox="0 0 40 40">
            <path
                d="M27.2,4.7v4.9h-2.9c-1.1,0-1.8,0.2-2.1,0.6c-0.4,0.5-0.6,1.1-0.6,2v3.5H27l-0.7,5.4h-4.7v14H16v-14h-4.7v-5.4H16v-4.1 c0-2.3,0.6-4.1,1.9-5.3s2.9-1.9,5.2-1.9C24.8,4.4,26.2,4.5,27.2,4.7L27.2,4.7z" />
        </symbol>
        <symbol id="icon-910" viewBox="0 0 40 40">
            <path
                d="M20,7c4.2,0,4.7,0,6.3,0.1c1.5,0.1,2.3,0.3,3,0.5C30,8,30.5,8.3,31.1,8.9c0.5,0.5,0.9,1.1,1.2,1.8c0.2,0.5,0.5,1.4,0.5,3 C33,15.3,33,15.8,33,20s0,4.7-0.1,6.3c-0.1,1.5-0.3,2.3-0.5,3c-0.3,0.7-0.6,1.2-1.2,1.8c-0.5,0.5-1.1,0.9-1.8,1.2 c-0.5,0.2-1.4,0.5-3,0.5C24.7,33,24.2,33,20,33s-4.7,0-6.3-0.1c-1.5-0.1-2.3-0.3-3-0.5C10,32,9.5,31.7,8.9,31.1 C8.4,30.6,8,30,7.7,29.3c-0.2-0.5-0.5-1.4-0.5-3C7,24.7,7,24.2,7,20s0-4.7,0.1-6.3c0.1-1.5,0.3-2.3,0.5-3C8,10,8.3,9.5,8.9,8.9 C9.4,8.4,10,8,10.7,7.7c0.5-0.2,1.4-0.5,3-0.5C15.3,7.1,15.8,7,20,7z M20,4.3c-4.3,0-4.8,0-6.5,0.1c-1.6,0-2.8,0.3-3.8,0.7 C8.7,5.5,7.8,6,6.9,6.9C6,7.8,5.5,8.7,5.1,9.7c-0.4,1-0.6,2.1-0.7,3.8c-0.1,1.7-0.1,2.2-0.1,6.5s0,4.8,0.1,6.5 c0,1.6,0.3,2.8,0.7,3.8c0.4,1,0.9,1.9,1.8,2.8c0.9,0.9,1.7,1.4,2.8,1.8c1,0.4,2.1,0.6,3.8,0.7c1.6,0.1,2.2,0.1,6.5,0.1 s4.8,0,6.5-0.1c1.6-0.1,2.9-0.3,3.8-0.7c1-0.4,1.9-0.9,2.8-1.8c0.9-0.9,1.4-1.7,1.8-2.8c0.4-1,0.6-2.1,0.7-3.8 c0.1-1.6,0.1-2.2,0.1-6.5s0-4.8-0.1-6.5c-0.1-1.6-0.3-2.9-0.7-3.8c-0.4-1-0.9-1.9-1.8-2.8c-0.9-0.9-1.7-1.4-2.8-1.8 c-1-0.4-2.1-0.6-3.8-0.7C24.8,4.3,24.3,4.3,20,4.3L20,4.3L20,4.3z" />
            <path
                d="M20,11.9c-4.5,0-8.1,3.7-8.1,8.1s3.7,8.1,8.1,8.1s8.1-3.7,8.1-8.1S24.5,11.9,20,11.9z M20,25.2c-2.9,0-5.2-2.3-5.2-5.2 s2.3-5.2,5.2-5.2s5.2,2.3,5.2,5.2S22.9,25.2,20,25.2z" />
            <path
                d="M30.6,11.6c0,1-0.8,1.9-1.9,1.9c-1,0-1.9-0.8-1.9-1.9s0.8-1.9,1.9-1.9C29.8,9.7,30.6,10.5,30.6,11.6z" />
        </symbol>
        <symbol id="icon-905" viewBox="0 0 40 40">
            <path
                d="M36.3,10.2c-1,1.3-2.1,2.5-3.4,3.5c0,0.2,0,0.4,0,1c0,1.7-0.2,3.6-0.9,5.3c-0.6,1.7-1.2,3.5-2.4,5.1 c-1.1,1.5-2.3,3.1-3.7,4.3c-1.4,1.2-3.3,2.3-5.3,3c-2.1,0.8-4.2,1.2-6.6,1.2c-3.6,0-7-1-10.2-3c0.4,0,1.1,0.1,1.5,0.1 c3.1,0,5.9-1,8.2-2.9c-1.4,0-2.7-0.4-3.8-1.3c-1.2-1-1.9-2-2.2-3.3c0.4,0.1,1,0.1,1.2,0.1c0.6,0,1.2-0.1,1.7-0.2 c-1.4-0.3-2.7-1.1-3.7-2.3s-1.4-2.6-1.4-4.2v-0.1c1,0.6,2,0.9,3,0.9c-1-0.6-1.5-1.3-2.2-2.4c-0.6-1-0.9-2.1-0.9-3.3s0.3-2.3,1-3.4 c1.5,2.1,3.6,3.6,6,4.9s4.9,2,7.6,2.1c-0.1-0.6-0.1-1.1-0.1-1.4c0-1.8,0.8-3.5,2-4.7c1.2-1.2,2.9-2,4.7-2c2,0,3.6,0.8,4.8,2.1 c1.4-0.3,2.9-0.9,4.2-1.5c-0.4,1.5-1.4,2.7-2.9,3.6C33.8,11.2,35.1,10.9,36.3,10.2L36.3,10.2z" />
        </symbol>
    </svg> */}
    <div id="wrapper">
        <div id="main">
            <div class="inner">
                <section id="home-section">
                    <div id="container02" class="container default full screen">
                        <div class="wrapper">
                            <div class="inner">
                                <h3 id="text08" class="style4">Risus ultricies</h3>
                                <h1 id="text07" class="style1">Hexagon</h1>
                                <p id="text15" class="style2">Massa tincidunt nunc pulvinar sapien et. Lectus arcu
                                    bibendum at varius. Ipsum dolor sit amet consectetur adipiscing.</p>
                                <form id="form02" method="post">
                                    <div class="inner">
                                        <div class="field"><input type="email" name="email" id="form02-email"
                                                placeholder="Enter email" maxlength="128" required /></div>
                                        <div class="field"><input type="text" name="site-url" id="form02-site-url"
                                                placeholder="Site Url" maxlength="128" /></div>
                                        <div class="actions"><button type="submit"><svg>
                                                    <use xlinkHref="#icon-a37"></use>
                                                </svg><span class="label">Pretium</span></button></div>
                                    </div><input type="hidden" name="id" value="form02" />
                                </form>
                            </div>
                        </div>
                    </div>
                    <div id="container06" class="container default full screen">
                        <div class="wrapper">
                            <div class="inner">
                                <h3 id="text27" class="style4">Auctor neque</h3>
                                <h2 id="text28" class="style3">Nibh tortor</h2>
                                <p id="text29" class="style5">Cras semper auctor neque vitae tempus. Volutpat maecenas
                                    volutpat blandit aliquam etiam erat. Mi sit amet mauris commodo quis imperdiet
                                    massa.</p>
                            </div>
                        </div>
                    </div>
                    <div id="container08" class="container columns full screen">
                        <div class="wrapper">
                            <div class="inner">
                                <div>
                                    <div id="image03" class="style1 image"><img
                                            src="assets/images/image03.svg?v=81f23adc" alt="" /></div>
                                    <h3 id="text06">Semper feugiat</h3>
                                    <p id="text25" class="style5">Hendrerit gravida rutrum quisque non tellus orci.
                                        Auctor neque vitae tempus quam pellente.</p>
                                </div>
                                <div>
                                    <div id="image04" class="style1 image"><img
                                            src="assets/images/image04.svg?v=81f23adc" alt="" /></div>
                                    <h3 id="text26">Turpis egestas</h3>
                                    <p id="text21" class="style5">Magna etiam tempor orci eu lobortis. Sed lectus
                                        vestibulum mattis ullamcorper velit.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="container05" class="container columns full screen">
                        <div class="wrapper">
                            <div class="inner">
                                <div>
                                    <h3 id="text35" class="style4">Sem integer</h3>
                                    <h2 id="text02" class="style3">Consequat</h2>
                                    <p id="text11" class="style5">Tortor dignissim convallis aenean et. Blandit cursus
                                        risus at ultrices mi tempus. Ornare arcu dui vivamus arcu felis bibendum ut
                                        tristique et.</p>
                                    <ul id="buttons01" class="style1 buttons">
                                        <li><a href="https://domain.ext" class="button n01"><svg>
                                                    <use xlinkHref="#icon-a37"></use>
                                                </svg><span class="label">Cras pulvinar</span></a></li>
                                    </ul>
                                </div>
                                <div>
                                    <div id="image01" class="image"><img src="assets/images/image01.svg?v=81f23adc"
                                            alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="container07" class="container default full screen">
                        <div class="wrapper">
                            <div class="inner">
                                <hr id="divider03"/>
                                <h2 id="text13" class="style3">Dolor sit amet</h2>
                                <p id="text18" class="style5">Id porta nibh venenatis cras sed felis eget. Blandit
                                    aliquam etiam erat velit scelerisque in dictum non. Felis bibendum ut tristique et
                                    egestas quis ipsum suspendisse ultrices.</p>
                            </div>
                        </div>
                    </div>
                    <div id="container03" class="container columns">
                        <div class="wrapper">
                            <div class="inner">
                                <div>
                                    <h3 id="text30" class="style4">Pretium viverra</h3>
                                    <h2 id="text22">$49</h2>
                                    <h3 id="text05" class="style6">Sollicitudin ac</h3>
                                    <p id="text24" class="style5">Tellus pellentesque eu tincidunt tortor aliquam nulla
                                        facilisi cras fermentum. Risus feugiat in ante metus dictum at tempor.</p>
                                    <ul id="buttons04" class="style1 buttons">
                                        <li><a href="https://domain.ext" class="button n01"><svg>
                                                    <use xlinkHref="#icon-a37"></use>
                                                </svg><span class="label">Morbi leo</span></a></li>
                                    </ul>
                                </div>
                                <div>
                                    <h3 id="text04" class="style4">Purus ut</h3>
                                    <h2 id="text32">$99</h2>
                                    <h3 id="text23" class="style6">Ultricies leo</h3>
                                    <p id="text10" class="style5">Malesuada fames ac turpis egestas maecenas pharetra
                                        convallis posuere. Cursus risus at ultrices mi tempus imperdiet nulla.</p>
                                    <ul id="buttons03" class="buttons">
                                        <li><a href="https://domain.ext" class="button n01"><svg>
                                                    <use xlinkHref="#icon-a37"></use>
                                                </svg><span class="label">Netus et</span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr id="divider01"/>
                    <div id="container04" class="container default">
                        <div class="wrapper">
                            <div class="inner">
                                <div id="image02" class="image"><img src="assets/images/image02.jpg?v=81f23adc"
                                        alt="" /></div>
                                <h3 id="text34" class="style4">Arcu non</h3>
                                <h3 id="text19" class="style6">Maria Rose</h3>
                                <p id="text20" class="style5">Mollis aliquam ut porttitor leo a diam sollicitudin tempor
                                    id. Aliquam sem et tortor consequat id porta. Aliquet sagittis id consectetur.</p>
                            </div>
                        </div>
                    </div>
                    <hr id="divider02"/>
                    <div id="container28" class="style1 container default full screen">
                        <div class="wrapper">
                            <div class="inner">
                                <h3 id="text17" class="style7">Sodales neque</h3>
                                <h2 id="text14" class="style1">Aliquet</h2>
                                <p id="text03">Enim neque volutpat ac tincidunt. Dignissim convallis aenean et tortor at
                                    risus viverra. Purus in mollis nunc sed id semper. Ut etiam sit amet nisl purus.</p>
                                <ul id="buttons02" class="style2 buttons">
                                    <li><a href="https://domain.ext" class="button n01"><svg>
                                                <use xlinkHref="#icon-901"></use>
                                            </svg><span class="label">Tincidunt eget</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div id="container01" class="container default full screen">
                        <div class="wrapper">
                            <div class="inner">
                                <p id="text16" class="style5">© Untitled. All rights reserved.</p>
                                <ul id="icons01" class="style1 icons">
                                    <li><a class="n01" href="https://domain.ext/path"><svg>
                                                <use xlinkHref="#icon-905"></use>
                                            </svg><span class="label">Twitter</span></a></li>
                                    <li><a class="n02" href="https://domain.ext/path"><svg>
                                                <use xlinkHref="#icon-910"></use>
                                            </svg><span class="label">Instagram</span></a></li>
                                    <li><a class="n03" href="https://domain.ext/path"><svg>
                                                <use xlinkHref="#icon-906"></use>
                                            </svg><span class="label">Facebook</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="done-section">
                    <div id="container36" class="container default full screen">
                        <div class="wrapper">
                            <div class="inner">
                                <h3 id="text01" class="style4">Erat nam at</h3>
                                <h2 id="text12">Thank you!</h2>
                                <p id="text09" class="style5">Tincidunt dui ut ornare lectus sit amet est. Quis risus
                                    sed vulputate odio ut enim blandit volutpat maecenas. Sit amet est placerat in
                                    egestas erat.</p>
                                <ul id="icons03" class="style1 icons">
                                    <li><a class="n01" href="https://domain.ext/path"><svg>
                                                <use xlinkHref="#icon-905"></use>
                                            </svg><span class="label">Twitter</span></a></li>
                                    <li><a class="n02" href="https://domain.ext/path"><svg>
                                                <use xlinkHref="#icon-910"></use>
                                            </svg><span class="label">Instagram</span></a></li>
                                    <li><a class="n03" href="https://domain.ext/path"><svg>
                                                <use xlinkHref="#icon-906"></use>
                                            </svg><span class="label">Facebook</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>

    
  </div>
  );
}

export default App;
